import React from "react";
import FreshCard from "../../components/freshcard";
import Jumbotron from "../../components/jumbotron";
import { useCollection } from "react-firebase-hooks/firestore";
import { useProvider } from "../../provider";
import FreshCardSkeleton from "../../components/freshcardSkeleton";
import ButtonJumbotron from "../../components/buttonJumbotron";
import ImageCard from "../../components/imageCard";
import MoreTulisan from "../../components/moreTulisan";
import { isMobile } from "react-device-detect";

function Home() {
  const { firestore, storage, user } = useProvider();
  const [items, setItems] = React.useState([]);
  const dataFirestore = firestore
    .collection("catatan")
    .orderBy("created", "desc");
  const [getQuery, loading] = useCollection(dataFirestore);
  const skeleton = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  /**state untuk handling foto atau tulisan */
  const [fotoView, setFotoView] = React.useState(false);

  React.useEffect(() => {
    if (getQuery) {
      setItems(getQuery.docs);
    }
  }, [getQuery]);
  const [numData, setNumData] = React.useState(8);
  const nextData = (e) => {
    // e.preventDefault();
    setNumData(numData + 6);
    if (numData === items.length) {
      setNumData(5);
    }
  };

  const hapusData = async (id, item) => {
    // console.log("ID : ", id);
    await firestore.collection("catatan").doc(`${id}`).delete();
    const fotoUrl = item.data().linkFoto;
    await storage.refFromURL(fotoUrl).delete();
    await item.ref.delete();
  };

  /**Tombol untuk switch tampilan ke mode gallery */
  const switchToGallery = () => {
    setFotoView(true);
  };
  const swtichToWriting = () => {
    setFotoView(false);
  };

  /**tampilkan lebih banyak data */
  const showMoreTulisan = () => {
    nextData();
  };
  return (
    <div className="text-white ">
      <Jumbotron />
      {loading ? (
        <div role="status" class="animate-pulse">
          <div class="flex items-center justify-center mb-12">
            <div class="h-[50px] w-[200px] bg-gray-200 rounded-lg dark:bg-gray-700 mx-4"></div>
          </div>
          <span class="sr-only">Loading...</span>
        </div>
      ) : fotoView ? (
        <div className="flex items-center justify-center mb-10">
          <ButtonJumbotron title="Baca Tulisan" onClick={swtichToWriting} />
        </div>
      ) : (
        <div className="flex items-center justify-center mb-10">
          <ButtonJumbotron title="Lihat Foto" onClick={switchToGallery} />
        </div>
      )}

      {fotoView ? (
        <div className="max-w-[1200px] mx-auto h-auto mt-7">
          <div className="justify-center grid md:grid-cols-1 lg:grid-cols-3 ">
            {loading
              ? skeleton.map((item) => <FreshCardSkeleton key={item} />)
              : items.slice(0, numData).map((item, number) => {
                  const data = item.data();
                  if (data.linkFoto) {
                    return (
                      <ImageCard
                        key={number}
                        linkFoto={data.linkFoto}
                        deskripsi={data.judul}
                        data={data}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
          </div>
        </div>
      ) : (
        <div className="max-w-[1200px] mx-auto h-auto mt-7">
          <div className="justify-center grid md:grid-cols-2 lg:grid-cols-3 ">
            {loading
              ? skeleton.map((item) => <FreshCardSkeleton key={item} />)
              : items.slice(0, numData).map((item, number) => {
                  const data = item.data();
                  return (
                    <FreshCard
                      item={data}
                      id={item.id}
                      key={number}
                      onHapusData={() => hapusData(item.id, item)}
                    />
                  );
                })}
            {loading ? (
              ""
            ) : numData >= items.length ? (
              ""
            ) : (
              <MoreTulisan action={showMoreTulisan} />
            )}
          </div>
        </div>
      )}

      {/* FLOATING BUTTON */}
      {isMobile ? (
        ""
      ) : user ? (
        <div data-dial-init class="fixed right-6 bottom-6 group">
          <a href="/tambah-catatan">
            <button
              type="button"
              class="flex items-center justify-center text-black bg-blue-700 rounded-full w-14 h-14 hover:bg-blue-800 dark:bg-[#00df9a] dark:hover:bg-white focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800"
            >
              <svg
                class="w-5 h-5 transition-transform group-hover:rotate-90"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
              <span class="sr-only">Open actions menu</span>
            </button>
          </a>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Home;
