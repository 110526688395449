import React from "react";

function FreshCardSkeleton() {
  return (
    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-4 my-4">
      <span>
        <h5 class="mb-3 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          <div role="status" class="max-w-sm animate-pulse">
            <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
            <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-60 mb-4"></div>
            <span class="sr-only">judul</span>
          </div>
        </h5>
      </span>
      <span>
        <h5 class="mb-3 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          <div role="status" class="max-w-sm animate-pulse">
            <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[120px] mb-3.5"></div>
            <span class="sr-only">Tanggal</span>
          </div>
        </h5>
      </span>
      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 mt-8">
        <div role="status" class="max-w-sm animate-pulse">
          <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-3.5"></div>
          <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[250px] mb-3.5"></div>
          <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-3.5"></div>
          <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[250px] mb-3.5"></div>
          <span class="sr-only">Loading...</span>
        </div>
      </p>
      <div role="status" class="max-w-sm animate-pulse mt-8">
        <div class="h-6 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[80px] mb-2.5"></div>
      </div>
    </div>
  );
}

export default FreshCardSkeleton;
