import React from "react";
import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "../components/error-pages";
import App from "../App";
import Home from "../pages/home";
import DetailCatatan from "../pages/catatan/detail";
import Add from "../pages/data/add";
import Login from "../pages/auth";
import Logout from "../pages/auth/logout";
import Delete from "../pages/data/delete";
import Tentang from "../pages/tentang";
import { tentangAdmin } from "../constant/tentang";
// import Testing from "../pages/testing";
// import AddSkeleton from "../pages/data/add/addSkeleton";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/catatan/:id",
        element: <DetailCatatan />,
      },
      {
        path: "/login-admin",
        element: <Login />,
      },
      {
        path: "/tambah-catatan",
        element: <Add />,
      },
      {
        path: "/logout-admin",
        element: <Logout />,
      },
      {
        path: "/hapus/:id",
        element: <Delete />,
      },
      {
        path: "/tentang",
        element: <Tentang {...tentangAdmin} />,
      },
      // {
      //   path: "/testing",
      //   element: <Testing />,
      // },
    ],
  },
]);

export default router;
