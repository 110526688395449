import React from "react";
import { useLocation } from "react-router-dom";

function ButtonNav() {
  const pathName = useLocation().pathname;
  return (
    <div class="fixed bottom-0 left-0 z-50 w-full h-14 bg-white  dark:bg-gray-800 dark:border-gray-600">
      <div class="flex h-full max-w-lg grid-cols-4 mx-auto font-medium justify-center">
        <button
          type="button"
          class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
        >
          <svg
            class={
              pathName === "/"
                ? "w-6 h-6 mb-1 text-[#00df9a] dark:text-[#00df9a]  "
                : "w-6 h-6 mb-1 text-gray-500 dark:text-gray-400  dark:group-hover:text-[#00df9a]"
            }
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
          </svg>
          <a
            href="/"
            class={
              pathName === "/"
                ? "text-sm text-[#00df9a] dark:text-[#00df9a]  "
                : "text-sm text-gray-500 dark:text-gray-400  dark:group-hover:text-[#00df9a]"
            }
          >
            Beranda
          </a>
        </button>
        <button
          type="button"
          class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
        >
          <svg
            class={
              pathName === "/tentang"
                ? "w-6 h-6 mb-1 text-[#00df9a] dark:text-[#00df9a]  "
                : "w-6 h-6 mb-1 text-gray-500 dark:text-gray-400  dark:group-hover:text-[#00df9a]"
            }
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z"></path>
          </svg>
          <a
            href="/tentang"
            class={
              pathName === "/tentang"
                ? "text-sm text-[#00df9a] dark:text-[#00df9a]  "
                : "text-sm text-gray-500 dark:text-gray-400  dark:group-hover:text-[#00df9a]"
            }
          >
            Tentang
          </a>
        </button>
      </div>
    </div>
  );
}

export default ButtonNav;
