import { useProvider } from "../../../provider";
import { useNavigate } from "react-router-dom";
function Logout() {
  const { auth } = useProvider();
  const navigate = useNavigate();

  auth.signOut();
  return navigate("/");
}

export default Logout;
