import React from "react";

function ButtonJumbotron({ title, isSubmit, disabled, ...anyPramaeter }) {
  return (
    <div>
      {/* <button
        disabled={disabled}
        {...anyPramaeter}
        className={
          isSubmit
            ? "focus:ring-4 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 rounded-md font-medium py-3 my-6 px-6 ml-4 w-[200px]"
            : "focus:ring-4 bg-[#00df9a] w-[200px] rounded-md font-medium py-3 my-6 px-6 ml-4 text-black dark:bg-[#00df9a] dark:hover:bg-[#038059] dark:focus:ring-gray-700"
        }
      >
        {title}
      </button> */}
      <button
        disabled={disabled}
        {...anyPramaeter}
        className={
          "h-[50px] w-[200px] text-black bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-md px-5 py-2.5 mr-2 mb-2 dark:bg-[#00df9a] dark:hover:bg-white dark:focus:ring-gray-700"
        }
      >
        {title}
      </button>
    </div>
  );
}

export default ButtonJumbotron;
