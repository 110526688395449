import React from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useProvider } from "../../../provider";
import { useParams } from "react-router-dom";
import DetailSkeleton from "./detailSkeleton";
import Tailbutton from "../../../components/tailbutton";
import { useNavigate } from "react-router-dom";
import ErrorPage from "../../../components/error-pages";

function DetailCatatan() {
  const { firestore } = useProvider();
  const navigate = useNavigate();
  let { id } = useParams();
  const query = firestore.collection("catatan").doc(id);
  const [item, setItem] = React.useState({});
  const [getItem, loading] = useDocument(query);

  React.useEffect(() => {
    if (query === undefined) {
      return <ErrorPage />;
    }

    if (getItem) {
      setItem(getItem.data());
    }
  }, [getItem, query]);

  const kembaliHandler = (e) => {
    e.preventDefault();
    navigate("/");
  };

  if (loading) {
    return <DetailSkeleton />;
  }

  const tanggalTerbit = new Date(item.created?.seconds * 1000)
    .toLocaleString()
    .split(",")[0];

  const judulWarnaWarni = item.judul?.split(" ");
  const judulWarnaHijauSlice =
    judulWarnaWarni?.slice(0, judulWarnaWarni.length / 2)?.join(" ") + " ";

  const judulWarnaPutih = judulWarnaWarni;
  const judulWarnaPuithSlice = judulWarnaPutih
    ?.slice(judulWarnaPutih.length / 2, judulWarnaPutih.length)
    ?.join(" ");

  return (
    <div className="w-full px-8">
      <div className="max-w-[1200px] h-auto mx-auto ">
        <div className="flex flex-col ">
          <h1 class="font-extrabold md:dark:text-6xl text-2xl  dark:text-white mx-auto w-[85%] text-center my-8">
            {item?.judul === undefined ? (
              <ErrorPage />
            ) : (
              <>
                <span className="text-[#00df9a]">{judulWarnaHijauSlice}</span>
                <span>{judulWarnaPuithSlice}</span>
              </>
            )}
          </h1>
          <p class="font-bold md:text-xl sm:text-sm  dark:text-gray-400 mx-auto w-[85%] text-center my-8">
            {`Diterbitkan pada ${tanggalTerbit}`}
          </p>
          <div className="max-w-[850px] mx-auto text-justify list-decimal">
            <div
              // id="tampilan-koran"
              // style={{
              //   WebkitColumnCount: isMobile ? 1 : 2,
              //   MozColumnCount: isMobile ? 1 : 2,
              //   columnCount: isMobile ? 1 : 2,
              // }}
              class="font-normal text-gray-400 list-decimal dark:lg:text-2xl  dark:text-gray-300 py-4 dark:font-semibold"
              dangerouslySetInnerHTML={{ __html: item.isi }}
            />
            {item.linkFoto === "" ? (
              ""
            ) : (
              <div className="flex justify-center py-8">
                <img
                  class="h-auto lg:max-w-lg md:max-w-md sm:max-w-full  transition-all duration-300 rounded-lg cursor-pointer filter grayscale hover:grayscale-0"
                  src={item.linkFoto}
                  alt="foto"
                />
              </div>
            )}

            <div className="flex justify-center py-8">
              {item?.judul === undefined ? (
                ""
              ) : (
                <Tailbutton title={"Kembali"} onClick={kembaliHandler} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailCatatan;
