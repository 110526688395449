function imageId(title) {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDay();
  const checkMonth = String(month).length === 2 ? month : `0${month}`;
  const checkDay = String(month).length === 2 ? day : `0${day}`;
  const dateCreated = `${year}${checkMonth}${checkDay}`;
  const imageId = `${title}-${dateCreated}`;
  return imageId;
}

export default imageId;
