import React from "react";

function AddSkeleton() {
  return (
    <>
      <div className="w-full h-auto px-4 py-8">
        <div className="max-w-[600px]  py-8 mx-auto">
          <div>
            <div class="grid gap-6 mb-6">
              <div>
                <label
                  for="first_name"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  <div role="status" class="animate-pulse flex flex-cols">
                    <div class="h-6 bg-gray-200 rounded-full dark:bg-gray-700 w-[75%] mb-5"></div>
                    <div class="h-6 bg-gray-200 rounded-full dark:bg-gray-700 w-[20%] mb-5 mx-auto"></div>

                    <span class="sr-only">Paragraf Kanan</span>
                  </div>
                </label>
              </div>
            </div>

            <div class="grid gap-6 mb-6">
              <div>
                <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
                  <div class="flex items-center w-full space-x-2">
                    <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                  </div>
                  <div class="flex items-center w-full space-x-2 max-w-[480px]">
                    <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                  </div>
                  <div class="flex items-center w-full space-x-2 max-w-[400px]">
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                  </div>

                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
          <div role="status" class="max-w-full animate-pulse">
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[80%] mb-3"></div>
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[90%] mb-3"></div>
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[80%] mb-3"></div>
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[70%] mb-3"></div>

            <span class="sr-only">Loading...</span>
          </div>
          <div
            role="status"
            class="mt-8 flex items-center justify-center h-56 max-w-full bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700"
          >
            <span class="sr-only">Loading...</span>
          </div>

          <div class="px-8">
            <div className="py-8"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddSkeleton;
