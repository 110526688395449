import React from "react";
import Typed from "react-typed";

function Jumbotron() {
  return (
    <div className="text-white">
      <div className="max-w-[800px] w-full h-auto mx-auto flex flex-col text-center justify-center py-16">
        <p className=" text-[#00df9a] md:text-7xl  sm:text-7xl text-5xl mb-4 font-extrabold">
          Tumbuh
        </p>
        <h1 className="md:text-7xl sm:text-7xl text-5xl font-extrabold mb-2">
          Dengan Filosofi Padi
        </h1>
        <div className="flex justify-center items-center">
          <p className="md:text-5xl sm:text-5xl text-4xl font-extrabold py-4 text-gray-500">
            Semakin
          </p>
          <Typed
            className="md:text-5xl sm:text-5xl text-4xl font-extrabold pl-2 md:pl-4 text-gray-300"
            strings={["Berisi", "Menunduk"]}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
      </div>
    </div>
  );
}

export default Jumbotron;
