import { useRouteError } from "react-router-dom";

export default function DesktopOnly() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <div className="text-white">
        <div className="max-w-[800px] w-full h-auto mx-auto flex flex-col text-center justify-center py-16">
          <p className="text-[#00df9a] md:text-4xl sm:text-4xl text-xl font-bold mb-4">
            Maaf, Akses telah dibatasi
          </p>
          <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold mb-2">
            401
          </h1>
          <div className="flex justify-center items-center">
            <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4">
              Hanya bisa diakses melalui mode Desktop
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
