import React, { useContext, createContext } from "react";

/**Library firebase */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import { firebaseConfig } from "../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

/**inisialisasi firebase app */
firebase.initializeApp(firebaseConfig);

const ContextProvider = createContext();

export const useProvider = () => {
  return useContext(ContextProvider);
};

function Provider({ children }) {
  /** inisialisasi dan deklarasi fungsi untuk authentikasi */
  const auth = firebase.auth();
  const [user, userLoading] = useAuthState(auth);

  /** inisialisasi firestore */
  const firestore = firebase.firestore();

  /** inisialisasi firebase storage */
  const storage = firebase.storage();

  /**State to storate and hadle */
  const [blogValue, setBlogValue] = React.useState({
    judul: "",
    isi: "",
  });

  return (
    <ContextProvider.Provider
      value={{
        blogValue,
        setBlogValue,
        firestore,
        user,
        userLoading,
        auth,
        storage,
      }}
    >
      {children}
    </ContextProvider.Provider>
  );
}

export default Provider;
