import React from "react";

function Tentang(props) {
  const {
    nama,
    pekerjaan,
    biografi_text_1,
    biografi_text_2,
    biografi_text_3,
    biografi_text_4,
    biografi_text_5,
    gambar_1,
  } = props;
  return (
    <>
      <div className="flex justify-center items-center  ">
        <div className="max-w-md mx-auto bg-gray-800 rounded-xl overflow-hidden sm:max-w-2xl mb-4">
          <div className="md:flex">
            <div className="p-8">
              <div className="tracking-wide text-3xl text-[#00df9a] font-extrabold">
                {nama}
              </div>
              <div className="block mt-1 text-xl leading-tight font-medium text-gray-400">
                {pekerjaan}
              </div>
              <p className="mt-2 text-justify text-gray-200 text-md">
                {biografi_text_1}
              </p>
            </div>
            <div className="md:flex-shrink-0">
              <img
                className="h-48 w-full object-cover md:h-full md:w-48 rounded-xl"
                src={gambar_1}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center  ">
        <div className="max-w-md mx-auto bg-gray-800 rounded-xl overflow-hidden sm:max-w-2xl mb-4">
          <div className="px-8 pt-4">
            <p className="mt-2 text-justify text-gray-200 text-md">
              {biografi_text_2}
            </p>
          </div>
          <div className="px-8 pt-4">
            <p className="mt-2 text-justify text-gray-200 text-md">
              {biografi_text_3}
            </p>
          </div>
          <div className="px-8 pt-4">
            <p className="mt-2 text-justify text-gray-200 text-md">
              {biografi_text_4}
            </p>
          </div>
          <div className="px-8 py-4">
            <p className="mt-2 text-justify text-gray-200 text-md">
              {biografi_text_5}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tentang;
