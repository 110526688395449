import React from "react";
import Navbar from "./components/navbar";
import { Outlet } from "react-router-dom";
import "flowbite";
import ButtonNav from "./components/buttonNav";

function App() {
  return (
    <div className="dark">
      <Navbar />
      <Outlet />

      <div className="lg:hidden md:hidden mt-16">
        <ButtonNav />
      </div>
    </div>
  );
}

export default App;
