import React from "react";
import { useNavigate } from "react-router-dom";
import { useProvider } from "../../provider";
import LoginSebagaiAdmin from "../../components/loginAdmin";
import RegistrasiAdmin from "../../components/registerAdmin";
function Login() {
  const { auth, user } = useProvider();
  const perluRegistrasiAdmin = false;
  /**membuat submision state */
  const [isSubmitting, setSubmitting] = React.useState(false);

  /**navigation */
  const navigate = useNavigate();

  /**Membuat form - form :
   * 1. Form Registrasi
   * 2. Form Login
   */
  const [formRegistrasi, setFormRegistrasi] = React.useState({
    email: "",
    password: "",
  });
  const [formLogin, setFormLogin] = React.useState({
    email: "",
    password: "",
  });

  const [errorRegistrasi, setErrorRegistrasi] = React.useState({
    email: "",
    password: "",
  });
  const [errorLogin, setErrorLogin] = React.useState({
    email: "",
    password: "",
  });

  /**handling input text */
  const handlingTextLogin = (e) => {
    setFormLogin({
      ...formLogin,
      [e.target.name]: e.target.value,
    });
    setErrorLogin({
      ...errorLogin,
      [e.target.name]: "",
    });
  };

  const handlingTextRegistrasi = (e) => {
    setFormRegistrasi({
      ...formRegistrasi,
      [e.target.name]: e.target.value,
    });
    setErrorRegistrasi({
      ...errorRegistrasi,
      [e.target.name]: "",
    });
  };

  /**Login admin */

  const validasiLogin = () => {
    const newError = { ...errorLogin };

    if (!formLogin.email) {
      newError.email = "Email tidak boleh kosong";
    }

    if (!formLogin.password) {
      newError.password = "Password tidak boleh kosong";
    }
    return newError;
  };

  /**Register admin baru
   *
   * admin baru hanya diinput sekali saja
   */

  const validasiRegistrasi = () => {
    const newError = { ...errorRegistrasi };

    if (!formRegistrasi.email) {
      newError.email = "Email tidak boleh kosong";
    }

    if (!formRegistrasi.password) {
      newError.password = "Password tidak boleh kosong";
    }
    return newError;
  };

  /**
   * membuat fungsi callback
   * untuk menbambahkan admin */
  const registrasiAdminBaru = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const findErrors = validasiRegistrasi();
    if (Object.values(findErrors).some((err) => err !== "")) {
      setErrorRegistrasi(findErrors);
    } else {
      console.log("no error");
      try {
        await auth.createUserWithEmailAndPassword(
          formRegistrasi.email,
          formRegistrasi.password
        );
        return navigate("/");
      } catch (e) {
        const newError = {};

        switch (e.code) {
          case "auth/email-already-in-use":
            newError.email = "Email sudah terdaftar";
            break;
          case "auth/invalid-email":
            newError.email = "Email tidak valid";
            break;
          case "auth/weak-password":
            newError.password = "Password lemah";
            break;
          case "auth/operation-not-allower":
            newError.email = "Metode email dan password tidak didukung";
            break;
          default:
            newError.email = "Terjadi kesalahan. Silahkan dicoba lagi";
            break;
        }
        setErrorRegistrasi(newError);
      }
    }

    setSubmitting(false);
  };

  /**menambahkan callback function untuk login */
  const loginSebagaiAdmin = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const findErrorLogin = validasiLogin();
    if (Object.values(findErrorLogin).some((err) => err !== "")) {
      setErrorLogin(findErrorLogin);
    } else {
      try {
        await auth.signInWithEmailAndPassword(
          formLogin.email,
          formLogin.password
        );
        if (user) {
          return navigate("/");
        }
      } catch (error) {
        const newError = {};
        switch (e.code) {
          case "auth/invalid-email":
            newError.email = "Email Tidak Valid";
            break;
          default:
            newError.email = "Terjadi kesalahan. Silahkan dicoba lagi";
            break;
        }
        setErrorLogin(newError);
      }

      setSubmitting(false);
    }
  };

  // console.log("errorLogin : ", errorLogin);
  const open = false;
  if (open === true) {
    console.log(isSubmitting);
  }

  if (user) return navigate("/");

  return (
    <>
      {perluRegistrasiAdmin ? (
        <RegistrasiAdmin
          formRegistrasi={formRegistrasi}
          errorRegistrasi={errorRegistrasi}
          handlingTextRegistrasi={handlingTextRegistrasi}
          submit={registrasiAdminBaru}
        />
      ) : (
        <LoginSebagaiAdmin
          formLogin={formLogin}
          errorLogin={errorLogin}
          handlingTextLogin={handlingTextLogin}
          submit={loginSebagaiAdmin}
        />
      )}
    </>
  );
}

export default Login;
