import { useProvider } from "../../../provider";
import { useNavigate, useParams } from "react-router-dom";

function Delete() {
  const { firestore } = useProvider();
  const { id } = useParams();
  const data = firestore.collection("catatan").doc(id);
  const navigate = useNavigate();
  data.ref.delete();
  return navigate("/");
}

export default Delete;
