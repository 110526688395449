const tentangAdmin = {
  nama: "Admin",
  pekerjaan: "Blogger & Web Programmer",
  biografi_text_1:
    "Admin adalah seorang blogger, penulis konten dan programmer web yang sudah berkecimpung dalam dunia digital selama lebih dari 7 tahun. Ia lahir dan tumbuh besar di NTB.",
  biografi_text_2:
    "Sejak muda, Admin memiliki ketertarikan yang kuat terhadap teknologi dan internet. Ia belajar banyak tentang blog dan media sosial, dan memulai blog pertamanya ketika masih duduk di bangku kuliah. Blog pertamanya berisi tentang pengembangan diri, dan menjadi platform di mana ia dapat mengekspresikan pikirannya dan berbagi pengetahuannya.",
  biografi_text_3:
    "Setelah lulus dari sarjana Teknik elektro, Admin memutuskan untuk fokus pada karirnya sebagai seorang penulis dan blogger. Ia mulai menulis konten untuk berbagai situs web dan blog, dan belajar data science & web programming  serta mobile  programming.",
  biografi_text_4:
    "Ketertarikan Admin pada teknologi dan internet tetap kuat, dan ia terus mengikuti tren terbaru dalam industri digital. Ia berusaha untuk terus berkembang dan belajar, serta membagikan pengetahuannya dengan pembaca dan pengikutnya.",
  biografi_text_5:
    "Ketika tidak sibuk menulis atau blogging, Admin senang menonton film, mengunjungi tempat wisata, atau memasak mie instan. Ia juga aktif dalam berbagai kegiatan sosial, termasuk membantu sesame manusia atau hablumminannas.",
  gambar_1:
    "https://firebasestorage.googleapis.com/v0/b/taninote-studio.appspot.com/o/profile%20picture%2F_6804155b-2432-4391-963a-fe8e291d3de1.jpg?alt=media&token=0da983b5-5c17-4078-98e4-9309b9f1f7c6",
  gambar_2:
    "https://firebasestorage.googleapis.com/v0/b/taninote-studio.appspot.com/o/profile%20picture%2F_d49d28c9-1e7d-4fb3-853b-0048e5171eba.jpg?alt=media&token=f12d4cbb-fb5c-41a1-b4f5-9051aceb7622",
  gambar_3:
    "https://firebasestorage.googleapis.com/v0/b/taninote-studio.appspot.com/o/profile%20picture%2F_cd919df7-1531-4310-b8f1-a1c14d40325d.jpg?alt=media&token=7ce983a5-457b-4e61-9ab3-ddf449dca5c8",
  gambar_4:
    "https://firebasestorage.googleapis.com/v0/b/taninote-studio.appspot.com/o/profile%20picture%2F_56805d27-d65b-4947-9b20-8c8f35ebb9d1.jpg?alt=media&token=e95e2b9f-e136-4e65-9480-43b6c73105a3",
  gambar_5:
    "https://firebasestorage.googleapis.com/v0/b/taninote-studio.appspot.com/o/profile%20picture%2F_e2b4fcf8-27d0-4bbf-9eed-2bd00f9364c5.jpg?alt=media&token=2091ecb1-d125-42f7-8102-138074a18e9a",
};

export { tentangAdmin };
