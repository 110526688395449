import React from "react";

function ImageCard({ linkFoto, deskripsi, data }) {
  const tanggalRilis = new Date(data?.created?.seconds * 1000)
    .toLocaleString()
    .split(",")[0];
  return (
    <div class="max-w-sm bg-white border border-gray-200 rounded-lg mx-4 my-4 shadow dark:bg-gray-800 dark:border-gray-700">
      <img
        class="rounded-t-lg h-[300px] w-full transition-all duration-300 rounded-lg cursor-pointer filter grayscale hover:grayscale-0"
        src={linkFoto}
        alt=""
      />

      <div class="p-5">
        <span className="text-[#00df9a] font-extrabold text-sm">
          {tanggalRilis}
        </span>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
          {deskripsi}
        </p>
      </div>
    </div>
  );
}

export default ImageCard;
