import React from "react";
function DetailSkeleton() {
  return (
    <div className="w-full py-12 px-8 ">
      <div className="max-w-[1200px] h-auto mx-auto  ">
        <div className="flex flex-col px-2 max-w-[850px] mx-auto">
          <div role="status" class="animate-pulse ">
            <div role="status" class="animate-pulse ">
              <div class="h-8 bg-gray-200 rounded-full dark:bg-gray-700 mx-auto w-[100%] mb-5 text-end"></div>
              <div class="h-8 bg-gray-200 rounded-full dark:bg-gray-700 mx-auto w-[90%] mb-5 text-end"></div>
              <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mx-auto w-[40%] my-10 text-end"></div>
            </div>
            <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 max-w-full mb-5 text-end"></div>
            <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 max-w-full mb-5"></div>
            <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 max-w-full mb-5"></div>
            <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 max-w-full mb-5"></div>
            <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 max-w-full mb-5"></div>
            <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[90%]"></div>

            <span class="sr-only">Paragraf Kanan</span>

            <div
              role="status"
              class="flex items-center  justify-center h-56 max-w-lg mx-auto bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700 mt-10"
            >
              <svg
                class="w-10 h-10 text-gray-200 dark:text-gray-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 18"
              >
                <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
              </svg>

              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailSkeleton;
