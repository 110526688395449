import "quill/dist/quill.snow.css"; // Add css for snow theme
import React from "react";
import uniqueId from "../../../config/uniqid";
import DesktopOnly from "../../../components/desktop-only";
import AddSkeleton from "./addSkeleton";
import { useProvider } from "../../../provider";
import { isBrowser } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useQuill } from "react-quilljs";
import imageId from "../../../utils/imageId";
import { useSnackbar } from "notistack";
import { Editor } from "@tinymce/tinymce-react";
// import { convertDeltaToHTML } from "../../../config/deltaToHtml";

/**
 *
 * sumber dari :
 * 1. https://github.com/zenoamaro/react-quill/blob/master/demo/index.js
 * 2. https://www.npmjs.com/package/quill-delta-to-html
 */

const APIKEY = "8588f9a5850bc3dc80c68ed7a33dc338";
const ENDPOINT = "https://api.imgbb.com/1/upload?key=" + APIKEY;
const EDITORAPI = "iwi4x7p12a6ckvg62gn5d4batqdwm8xtmtyhfzbmh80449tu";

function Add() {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [premalink, setPremalink] = React.useState("");
  const [idArtikel, setIdArtikel] = React.useState("");
  const { firestore, storage } = useProvider();
  const { enqueueSnackbar } = useSnackbar();

  /**Navigation */
  const navigate = useNavigate();

  /**image storage */
  const imageData = storage.ref("foto");

  const { user, userLoading } = useProvider();
  const [form, setForm] = React.useState({
    judul: "",
    isi: "",
    created: "",
    linkFoto: "",
  });

  const [error, setError] = React.useState({
    judul: "",
    linkFoto: "",
  });

  //**REACT QUILL */
  const { quill } = useQuill();

  // Insert Image(selected by user) to quill
  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, "image", url);
  };

  // Upload Image to Image Server such as AWS S3, Cloudinary, Cloud Storage, etc..
  const saveToServer = async (file) => {
    const body = new FormData();
    body.append("file", file);

    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("image", file);

      fetch(ENDPOINT, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          resolve(insertToEditor(result.data.url));
        })
        .catch((error) => {
          reject("Upload failed");
          console.error("Error:", error);
        });
    });
  };

  // Open Dialog to select Image File
  const selectLocalImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };
  /**REACT QUILL */

  React.useEffect(() => {
    setIdArtikel(uniqueId());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleJudul = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setError({
      ...error,
      [e.target.name]: "",
    });
    const output =
      e.target.value.toLowerCase().split(" ").join("-") + "-" + idArtikel;
    setPremalink(output);
  };

  /**Membuat validasi form judul
   *
   */
  const validasi = () => {
    const newError = { ...error };

    if (!form.judul) {
      newError.judul = "Judul tidak boleh kosong";
    }

    return newError;
  };

  const submitData = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const findErrors = validasi();
    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      await firestore
        .collection("catatan")
        .doc(premalink)
        .set({
          ...form,
          created: new Date(),
        });
    }
    setSubmitting(false);
    enqueueSnackbar("Post berhasil ditambahkan", { variant: "success" });
    setTimeout(() => {
      window.location.href = "/";
    }, 2500);
  };

  if (quill) {
    quill.on("text-change", (delta, oldDelta, source) => {
      setForm({
        ...form,
        isi: quill.getContents().ops,
      });
    });
    quill.getModule("toolbar").addHandler("image", selectLocalImage);
  }

  /**upload foto */
  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    const idImg = imageId(form.judul);
    const reader = new FileReader();
    if (!["image/png", "image/jpeg", "image/jpg"]) {
      setError((error) => ({
        ...error,
        linkFoto: `Tipe file tidak didukung ${file.type}`,
      }));
      reader.onabort = () => {
        setError((error) => ({
          ...error,
          linkFoto: "File tidak bisa dibaca",
        }));
      };
    }
    reader.onload = async () => {
      setSubmitting(true);
      const fotoExt = file.name.substring(file.name.lastIndexOf("."));
      const fotoRef = await imageData.child(`${idImg}${fotoExt}`);
      const fotoSnapshot = await fotoRef.putString(reader.result, "data_url");
      const fotoUrl = await fotoSnapshot.ref.getDownloadURL();
      setForm((currentForm) => ({
        ...currentForm,
        linkFoto: fotoUrl,
      }));
      setSubmitting(false);
      enqueueSnackbar("Foto terupload", { variant: "success" });
    };
    reader.readAsDataURL(file);
    // setSubmitData(false);
    setError({ ...error, linkFoto: "" });
  };

  if (userLoading) {
    return <AddSkeleton />;
  } else if (!user) {
    return navigate("/login-admin");
  } else
    return (
      <>
        {isBrowser ? (
          <div className="w-full h-auto px-4 py-4">
            <div className="max-w-[600px] py-8 mx-auto">
              <div className="">
                <label
                  for="message"
                  class="block mb-5 text-4xl font-bold text-gray-900 dark:text-gray-400"
                >
                  Tambah Data
                </label>
              </div>
              <div>
                <div class="flex items-center">
                  <label for="judul" class="sr-only">
                    Judul
                  </label>
                  <div class="relative w-[85%]">
                    <input
                      type="text"
                      id="judul"
                      name="judul"
                      value={form.judul}
                      // class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      class={
                        error.judul
                          ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400"
                          : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      }
                      placeholder="Judul Artikel"
                      onChange={handleJudul}
                      required
                    />
                  </div>

                  <button
                    onClick={submitData}
                    disabled={isSubmitting ? true : false}
                    className={
                      isSubmitting
                        ? "text-white font-medium rounded-lg text-sm px-6 p-2.5 ml-4  dark:bg-gray-700 "
                        : "text-black bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-6 p-2.5 ml-4  dark:bg-[#00df9a] dark:hover:bg-white dark:focus:ring-gray-700"
                    }
                  >
                    {isSubmitting ? "Loading" : "Submit"}
                  </button>
                </div>

                <div className="dark:bg-gray-300 mt-8 mb-8">
                  {/* <div
                    ref={quillRef}
                    className=" h-auto text-black text-sm block w-full p-2.5 dark:bg-gray-300 dark:border-gray-300 rounded-lg"
                  /> */}
                  <>
                    <Editor
                      apiKey={EDITORAPI}
                      // onInit={(evt, editor) => (editorRef.current = editor)}
                      // initialValue="<p>Silahkan ketikan sesuatu...!</p>"
                      value={form.isi}
                      onEditorChange={(newValue, value) =>
                        setForm({ ...form, isi: newValue })
                      }
                      disabled={isSubmitting ? true : false}
                      init={{
                        // selector: "textarea#default",
                        height: 500,
                        menubar: true,
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "code",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "code",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | blocks | " +
                          "bold italic underline forecolor bullist numlist |" +
                          "alignleft alignjustify alignright  outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        icons: "material",
                      }}
                    />
                  </>
                </div>

                <div className="">
                  <label
                    for="message"
                    class="block mb-5 text-4xl font-bold text-gray-900 dark:text-gray-400"
                  >
                    Featured Image
                  </label>
                  <div className="flex justify-end items-end mb-8">
                    <input
                      class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      id="file_input"
                      type="file"
                      accept="image/*"
                      onChange={handleUploadFile}
                    />
                  </div>

                  {/* <textarea
                    disabled
                    value={convertDeltaToHTML(form.isi)}
                    id="message"
                    rows="10"
                    class="block p-5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write your thoughts here..."
                  ></textarea> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <DesktopOnly />
        )}
      </>
    );
}

export default Add;
