import React from "react";

function LoginSebagaiAdmin({
  formLogin,
  errorLogin,
  handlingTextLogin,
  submit,
}) {
  return (
    <>
      <div className="pt-16">
        <section class="bg-gray-50 dark:bg-black">
          <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto  lg:py-0 ">
            <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-black dark:border-black">
              <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Masuk sebagai Administrator
                </h1>

                <div>
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formLogin.email}
                    onChange={handlingTextLogin}
                    class={
                      errorLogin.email
                        ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400"
                        : "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    }
                    placeholder="username@gmail.com"
                    required
                  />
                  {errorLogin.email && (
                    <p class="mt-2 text-sm text-red-600 dark:text-red-500">
                      {errorLogin.email}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    for="password"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={formLogin.password}
                    onChange={handlingTextLogin}
                    placeholder="••••••••"
                    class={
                      errorLogin.password
                        ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400"
                        : "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    }
                    required
                  />
                  {errorLogin.password && (
                    <p class="mt-2 text-sm text-red-600 dark:text-red-500">
                      {errorLogin.password}
                    </p>
                  )}
                </div>
                <div className="py-2">
                  <button
                    type="submit"
                    onClick={submit}
                    class=" w-full text-black bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#00df9a] dark:hover:bg-[#038059] dark:focus:ring-primary-800 dark:focus:ring-gray-700"
                  >
                    Masuk
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default LoginSebagaiAdmin;
