const firebaseConfig = {
  apiKey: "AIzaSyC-vYpvuXYQBFM7JjVLhdkDvtOVoy8WifQ",
  authDomain: "taninote-studio.firebaseapp.com",
  projectId: "taninote-studio",
  storageBucket: "taninote-studio.appspot.com",
  messagingSenderId: "347709072027",
  appId: "1:347709072027:web:d7b4fa64f4f8be75b5dc3e",
  measurementId: "G-5YPVKQN35C",
};
export { firebaseConfig };
