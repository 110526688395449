import React from "react";
import { useProvider } from "../provider";

function FreshCard({ item, id, onHapusData }) {
  const { user } = useProvider();
  const judulWarnaWarni = item.judul?.split(" ");
  const judulWarnaHijauSlice =
    judulWarnaWarni?.slice(0, judulWarnaWarni.length / 2)?.join(" ") + " ";

  const judulWarnaPutih = judulWarnaWarni;
  const judulWarnaPuithSlice = judulWarnaPutih
    ?.slice(judulWarnaPutih.length / 2, judulWarnaPutih.length)
    ?.join(" ");

  const tanggalRilis = new Date(item?.created?.seconds * 1000)
    .toLocaleString()
    .split(",")[0];
  return (
    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-4 my-4 relative">
      <span>
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          <>
            <span className="text-[#00df9a]">{judulWarnaHijauSlice}</span>
            <span>{judulWarnaPuithSlice}</span>
          </>
        </h5>
      </span>
      <p class="mb-10 font-normal text-gray-700 dark:text-gray-400 inlin">
        <span className="text-[#00df9a] font-extrabold text-sm">
          {tanggalRilis}
        </span>
        <div
          style={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
          }}
          className="dark:text-md"
          dangerouslySetInnerHTML={{
            __html: item.isi,
          }}
        />
      </p>
      <a
        href={"catatan/" + id}
        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center  rounded-lg focus:ring-4 focus:outline-none text-black dark:bg-[#00df9a] dark:hover:bg-white dark:focus:ring-gray-700 absolute bottom-4"
      >
        Baca
        <svg
          aria-hidden="true"
          class="w-4 h-4 ml-2 mr-1"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </a>
      {user && (
        <button
          onClick={onHapusData}
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center  rounded-lg focus:ring-4 focus:outline-none text-white dark:bg-gray-800  dark:focus:ring-gray-700 absolute bottom-4 right-6"
        >
          Hapus
          <svg
            class="w-4 h-4 ml-2 mr-1"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              clip-rule="evenodd"
              fill-rule="evenodd"
              d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
            ></path>
          </svg>
        </button>
      )}
    </div>
  );
}

export default FreshCard;
